import { Background } from '../../../styles/CommonStyles'
import Input from './Input'

const DateInput = ({
	value,
	openCalendar,
	handleValueChange,
	label,
	placeholder = '',
	isProfile = false,
	...rest
}) => {
	return (
		<div onClick={openCalendar} {...rest}>
			<Background bg={isProfile ? 'mainBg' : 'primaryBg'}>
				<Input
					value={value}
					placeholder={placeholder}
					onInputChange={handleValueChange}
					size={isProfile ? 'big' : 'small'}
					label={label}
				/>
			</Background>
		</div>
	)
}

export default DateInput
