import { useQuery } from 'react-query'
import { filterFetch, normalFetch } from '../../fetch-api/get'

export const panelKeys = {
	inAndOutExchange: 'inAndOutExchange',
	allFeeAmount: 'allFeeAmount',
	allStakingAmount: 'allStakingAmount',
}

export const useGetInAndOutExchange = (dateRange) => {
	let params = {}
	if (dateRange?.length > 1) {
		const from = new Date(dateRange[0])
		const to = new Date(dateRange[1])
		params.from_date = `${from.getDate()}/${from.getMonth() + 1}/${from.getFullYear()}`
		params.to_date = `${to.getDate()}/${to.getMonth() + 1}/${to.getFullYear()}`
	}
	return useQuery({
		queryFn: () => filterFetch(params, 'wallets/admin/all-wallets/statistics/in_and_out_exchange'),
		queryKey: [panelKeys.inAndOutExchange, dateRange],
		select: (res) => res?.data?.data,
	})
}

export const useGetAllFeeAmount = (dateRange) => {
	let params = {}
	if (dateRange?.length > 1) {
		const from = new Date(dateRange[0])
		const to = new Date(dateRange[1])
		params.from_date = `${from.getDate()}/${from.getMonth() + 1}/${from.getFullYear()}`
		params.to_date = `${to.getDate()}/${to.getMonth() + 1}/${to.getFullYear()}`
	}

	return useQuery({
		queryFn: () => filterFetch(params, 'wallets/admin/all-wallets/statistics/all_fee_amount'),
		queryKey: [panelKeys.allFeeAmount, params],
		select: (res) => res?.data?.data,
	})
}

export const useGetAllStakingAmount = (dateRange) => {
	let params = {}
	if (dateRange?.length > 1) {
		const from = new Date(dateRange[0])
		const to = new Date(dateRange[1])
		params.from_date = `${from.getDate()}/${from.getMonth() + 1}/${from.getFullYear()}`
		params.to_date = `${to.getDate()}/${to.getMonth() + 1}/${to.getFullYear()}`
	}

	return useQuery({
		queryFn: () =>
			filterFetch(params, 'wallets/admin/all-wallets/statistics/all_user_staking_amount'),
		queryKey: [panelKeys.allStakingAmount, dateRange],
		select: (res) => res?.data?.data,
	})
}
